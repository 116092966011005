<template>
<span class="footer-links">
    <div class="footer-side-link-container" id="data-analytics-footer-link-container">
        <a class="footer-link" @click="scrollToDomain('data-analytics-section')">Data Analytics</a>
    </div>
    <div class="footer-link-container" id="ai-footer-link-container">
        <a class="footer-link" id="ai-link" @click="scrollToDomain('ai-section')">AI</a>
    </div>
    <div class="footer-side-link-container" id="web-development-footer-link-container">
        <a class="footer-link" @click="scrollToDomain('web-development-section')">Web Development</a>
    </div>
</span>
</template>

<script>
export default {
  name: 'SimpleHeader',

  methods: {
    scrollToDomain(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior:'smooth' });
      element.click()
    }
  }
}
</script>