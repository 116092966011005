<template>
  <div class="quote-container">
    <transition :name="transition_direction" mode="out-in">
      <blockquote class="quote-and-reference" :key="currentStepId">
        <p class="quote">{{ quotes[currentStepId].quote }}</p>
        <footer class="quote-reference">-{{ quotes[currentStepId].author }}</footer>
      </blockquote>
    </transition>
  </div>
</template>

<script>
import quotes from '@/misc/quotes.js'
import { eventBus } from '@/event-bus.js';

export default {
  props: {
    partner: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      quotes: quotes[this.partner], // there are quotes for steps and for projects
      prevStepId: -1,
      currentStepId: 0,
      transition_direction: "right",
    };
  },
  mounted() {
    eventBus.on('updateFocusedStep', this.handleUpdateFocusedStep);
  },
  beforeUnmount() {
    eventBus.off('updateFocusedStep', this.handleUpdateFocusedStep);
  },
  methods: {
    handleUpdateFocusedStep(elementId) {
      this.prevStepId = this.currentStepId;
      this.currentStepId = elementId;

      if (window.innerWidth < 680) {
        if (this.prevStepId < this.currentStepId) {
          this.transition_direction = "bottom";
      } else {
          this.transition_direction = "top";
      }
      } else {
        if (this.prevStepId < this.currentStepId) {
          this.transition_direction = "right";
      } else {
          this.transition_direction = "left";
      }
      }
      // fconsole.log(this.transition_direction);
      // console.log("prev: " + this.prevStepId, "current: " + this.currentStepId, "direction: " + this.transition_direction)
    },
  },
};
</script>

<style scoped>

/* Desktop Version Transitions */

/* Entering transitions */
.right-enter-active {
  transition: transform 0.75s ease-in-out;
  transform: translateX(-200%);
}
.left-enter-active {
  transition: transform 0.75s ease-in-out;
  transform: translateX(200%);
}

/* Leaving transitions */
.right-leave-active {
  transition: transform 0.75s ease-in-out;
  transform: translateX(200%);
}
.left-leave-active {
  transition: transform 0.75s ease-in-out;
  transform: translateX(-200%);
}

/* Ensure the element is in its final position */
.right-enter-to, .left-enter-to {
  transform: translateX(0);
}

/* Mobile Version Transitions */

/* Entering transitions */
.top-enter-active, .bottom-enter-active {
  transition: transform 0.75s ease-in-out;
  transform-origin: center center;
}

.bottom-enter-active {
  transform: translateY(-100%);
}

.bottom-leave-active {
  transform: translateY(100%);
}

.top-enter-active {
  transform: translateY(100%);
}

.top-leave-active {
  transform: translateY(-100%);
}

/* Leaving transitions */
.top-leave-active, .bottom-leave-active {
  transition: transform 0.75s ease-in-out;
  transform-origin: center center;
}

/* Ensure the element is in its final position */
.top-enter-to, .bottom-enter-to {
  transform: translateY(0);
}
</style>
