<template>
    <div class="contact-info-container">
        <div class="contact-info" @click="openWhatsApp">
            <img class="contact-icon" src="@/assets/icons/whatsApp_black.svg">
            <div class="contact-text"><p>+43 677 643 79087</p></div>
        </div>
        <div class="contact-info" @click="openEmail">
            <img class="contact-icon" src="@/assets/icons/email_icon.svg">
            <div class="contact-text"><p>office@natealexeev.dev</p></div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        openWhatsApp() {
            window.open('https://wa.me/4367764379087', '_blank');
        },
        openEmail() {
            window.location.href = 'mailto:office@natealexeev.dev';
        }
    }
}
</script>