<template>
  <div class="domains-container" id="projectsAccordion">
    <div data-bs-accordion :class="`accordion ${domain.className}`" v-for="(domain, index) in domains" :key="index">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button text-center" :id="`${domain.className}`" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" :aria-controls="`collapse${index}`" @click="equalizeProcessWidths(`.${domain.className}`)">
            {{ domain.title }}
          </button>
        </h2>
        <div :id="`collapse${index}`" class="accordion-collapse collapse" data-bs-parent="#projectsAccordion">
          <div class="accordion-body">
            <div class="flag-container">
              <div v-for="(box, boxIndex) in domain.boxes" :key="boxIndex" :class="`flag-section ${box.title.toLowerCase()}`" 
                :id="`flag-section-${box.title.toLowerCase()}`" 
                @mouseenter="updateFocusedProcess(box.title.toLowerCase(), none)"
                @mouseleave="updateFocusedProcess(none, none)"
                >
                
                <div :class="`section-title-container ${domain.className}`">
                  <h3 class="section-title">{{ box.title }}</h3>
                </div>
                
                <div class="processses-column">
                  <div class="processes-container">
                    <div v-for="(process, processIndex) in box.processes" :key="processIndex" 
                      :id="`${process.toLowerCase()}`" 
                      class="process"
                      @mouseenter="updateFocusedProcess(box.title.toLowerCase(), process.toLowerCase())"
                      @mouseleave="updateFocusedProcess(box.title.toLowerCase(), none)"
                      >
                      {{ process }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="stack-line-container">
              <StackLine :domain="domain.className.replace('-section', '')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, nextTick, onMounted } from 'vue';
import { useWindowSize } from '@vueuse/core'
import { eventBus } from '@/event-bus.js';
import StackLine from './StackLine.vue'
import stackItems from '@/misc/stackItems.js'

export default {
  components: {
    StackLine
  },
  setup() {
    const { width, height } = useWindowSize()
    const domains = ref([
      {
        title: 'Data Analytics',
        className: 'data-analytics-section',
        boxes: [
          { title: 'Questions', processes: ['Data Acquisition', 'Data Wrangling', 'Data Pipelines'] },
          { title: 'Analytics', processes: ['Statistics', 'Visualization', 'Modelling'] },
          { title: 'Insight', processes: ['Reports', 'Presentations', 'Web Apps'] },
        ],
      },
      {
        title: 'AI',
        className: 'ai-section',
        boxes: [
          { title: 'Problem', processes: ['Problem Definition', 'Solution Engineering', 'Data Engineering'] },
          { title: 'Engineering', processes: ['Model Selection', 'Feature Engineering', 'Machine Learning'] },
          { title: 'Automation', processes: ['Testing', 'Deployment', 'Integration'] },
        ],
      },
      {
        title: 'Web Development',
        className: 'web-development-section',
        boxes: [
          { title: 'Design', processes: ['UI/UX Design', 'Architecture', 'Prototyping'] },
          { title: 'Development', processes: ['Front-End', 'Back-End', 'Full-Stack'] },
          { title: 'Deployment', processes: ['Testing', 'Deployment', 'Maintenance'] },
        ],
      }
    ]);

    function equalizeProcessWidths(rowSelector) {
      const row = document.querySelector(rowSelector);
      const sections = row.querySelectorAll('.flag-section');

      //console.log("equalizing ", rowSelector)

      sections.forEach(section => {
        const processesContainer = section.querySelector('.processes-container')
        const processes = processesContainer.querySelectorAll('.process');

        processes.forEach(process => {
          process.style.width = 'fit-content';
        });

        let maxWidth = 0;
        processes.forEach(process => {
          const width = process.offsetWidth;
          if (width > maxWidth) {
            maxWidth = width;
          }
        });

        if (maxWidth > 0) {
          processesContainer.style.width = `${maxWidth}px`;
          processes.forEach(process => {
            process.style.width = '100%';
          });
        }
      });
    }

    onMounted(() => {
      window.addEventListener('resize', () => {
        domains.value.forEach(domain => {
            equalizeProcessWidths(`.${domain.className}`);
        });
      });
    });

    let timeout;
    watch(width, () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        domains.value.forEach(domain => {
          equalizeProcessWidths(`.${domain.className}`);
        });
      }, 200); // 200ms delay
    });

    function updateFocusedProcess(section, process) {
      eventBus.emit('updateFocusedSectionProcess', {"section": section, "process": process});
    }

    eventBus.on('updateFocusedSkill', (skill) => {
      if (skill == undefined) {
        // remove .process-active class from all Processes
        const processes = document.querySelectorAll('.process');
        processes.forEach(process => {
          process.classList.remove('process-active');
        });
      } else {
        // add .process-active class to all the focusProcesses of that skill
        const focusProcesses = stackItems["stack_items"][skill]["focusProcesses"];
        focusProcesses.forEach(process => {
          const processElement = document.getElementById(process);
          if (processElement) {
            processElement.classList.add('process-active');
          }
        });
      }
    });

    
    return {
      domains,
      equalizeProcessWidths,
      updateFocusedProcess,
    }
  }
}
</script>