<template>
  <div class="contact-frame-container" id="contact-form">
    <div class="form-title-column">
      <h2 class="form-title">Let's Get Started!</h2>
    </div>
    <div class="form-column">
      <div class="form-container">
        <form class="contact-form" id="contactForm" @submit="handleSubmit">
            <input class="form-input" type="text" title="Name" id="name" name="name" v-model="name" placeholder="Name:" required @focus="scrollToInput($event) " >
            
            <input class="form-input" type="email" title="Email" id="email" name="email" v-model="email" placeholder="Email:" required @focus="scrollToInput($event)">

            <input class="form-input" type="tel" title="Phone" id="phone" name="phone" v-model="phone" placeholder="Phone:" @focus="scrollToInput($event)">
            
            <select class="form-input input-select" title="Objective Domain" id="objective_domain" name="objective_domain" v-model="objective_domain" required @focus="scrollToInput($event)">
              <option value="" selected>Objective Domain:</option>
              <option value="data_analysis">Data Analysis</option>
              <option value="ai">AI</option>
              <option value="web_development">Web Development</option>
              <option value="other">Other</option>
            </select>
            
            <textarea class="form-input form-input-large" id="message" title="Message" name="message" v-model="message" placeholder="Message:" required @focus="scrollToInput($event)"></textarea>
        
            <div class="button-row">
              <button class="form-submit-button" type="submit" form="contactForm" @mouseover="changeIconColor" @mouseleave="resetIconColor">
                Send It!
                <img :src="iconSrc" alt="Ship It Icon" class="button-icon">
              </button>
            </div>
          </form>
      </div>
      <div class="form-submit-message-container">
        <p class="form-submit-message" v-if="formSubmitted">{{ formSubmitMessage }}</p>
      </div>
    </div>
  </div>

  <ContactInfo/>
</template>

<script>
import { tryOnMounted } from '@vueuse/core';
import ContactInfo from './ContactInfo.vue';

export default {
  components: {
    ContactInfo
  },
  name: 'ContactForm',
  mounted () {
    this.preloadIcons()
  },
  data () {
    return {
      name: '',
      objective_domain: '',
      email: '',
      message: "",
      phone: "",
      iconSrc: require('@/assets/icons/icon_shipit.svg'),
      telegram_bot_token: process.env.VUE_APP_TELEGRAM_BOT_TOKEN,
      telegram_chat_id: process.env.VUE_APP_TELEGRAM_CHAT_ID,
      formSubmitted: false,
      formSubmitMessage: 'Thank you! The form has been submited successfully!'
    }
  },
  methods: {
    handleSubmit (event) {
      event.preventDefault();

      if (event.target.checkValidity()) {
        const telegram_message = `Name: ${this.name}\nEmail: ${this.email}\nPhone: ${this.phone}\nObjective Domain: ${this.objective_domain}\nMessage: ${this.message}`;
        fetch(`https://api.telegram.org/bot${this.telegram_bot_token}/sendMessage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            chat_id: this.telegram_chat_id,
            text: telegram_message
          })
        })
        .then(() => {
          // Reset the form after successful submission
          this.name = '';
          this.objective_domain = '';
          this.phone = "";
          this.email = '';
          this.message = '';
          // Add any other form field resets here
          this.formSubmitted = true;
          this.formSubmitMessage = 'Thank you! The form has been submited successfully!';
        })
        .catch(error => {
          this.formSubmitted = true;
          this.formSubmitMessage = 'Error while sending the form! Please use the contact info below to reach out directly!';
          console.error('Error sending message:', error);
        });
      }
    },
    preloadIcons () {
      this.arrowWhite = new Image()
      this.arrowBlack = new Image()
      this.arrowWhite.src = require('@/assets/icons/icon_shipit.svg')
      this.arrowBlack.src = require('@/assets/icons/icon_shipit_black.svg')
      this.iconSrc = this.arrowWhite.src
    },
    changeIconColor() {
      this.iconSrc = this.arrowBlack.src
    },
    resetIconColor() {
      this.iconSrc = this.arrowWhite.src
    },
    scrollToInput(event) {
      setTimeout(() => {
        event.target.scrollIntoView({
          behavior: 'smooth'
        });
      }, 150);
    }
  }
}
</script>
