const projects = [
    {
      title: 'Online Streaming Data Analytics',
      className: 'streaming_project'
    },
    {
      title: 'Startup User Growth Projection',
      className: 'projections_project'
    },
    {
      title: "Realtime Online-Sentiment-Based Cryptocurrency Trading",
      className: 'trading_project'
    },
    {
      title: "RealScanner",
      className: 'realscanner'
    },
  ];

  export default {
    projects
  };
