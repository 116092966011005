const stack_items = {
    // Analytics
    "python": {
        domains: ["all"],
        focusSections: ["all"],
        focusProcesses: ["data acquisition", "data wrangling", "data pipelines", "machine learning", "data engineering", "feature engineering", "back-end", "full-stack"]   
    },
    "R": {
        domains: ["data-analytics"],
        focusSections: ["all"],
        focusProcesses: ["data acquisition", "data wrangling", "data pipelines", "statistics", "visualization", "modelling"]   
    },
    "powerBI": {
        domains: ["data-analytics"],
        focusSections: ["insight", "analytics"],
        focusProcesses: ["visualization", "reports"]   
    },
    "Shiny": {
        domains: ["data-analytics"],
        focusSections: ["insight", "analytics"],
        focusProcesses: ["visualization", "web apps"]   
    },
    "Selenium": {
        domains: ["data-analytics"],
        focusSections: ["questions"],
        focusProcesses: ["data acquisition"]   
    },
    "VirtualBox": {
        domains: ["data-analytics"],
        focusSections: ["questions"],
        focusProcesses: ["data acquisition"]   
    },
    "docker": {
        domains: ["data-analytics"],
        focusSections: ["questions"],
        focusProcesses: ["data acquisition"]   
    },
    "linux": {
        domains: ["data-analytics"],
        focusSections: ["questions"],
        focusProcesses: ["data acquisition"]   
    },
    "R-Markdown": {
        domains: ["data-analytics"],
        focusSections: ["insight"],
        focusProcesses: ["reports", "visualization"]   
    },
    "MS Office": {
        domains: ["data-analytics"],
        focusSections: ["insight"],
        focusProcesses: ["presentations", "reports"]   
    },
    "Flask": {
        domains: ["data-analytics"],
        focusSections: ["insight"],
        focusProcesses: ["web apps"]   
    },
    // AI
    "Tensorflow": {
        domains: ["ai"],
        focusSections: ["ai"],
        focusProcesses: ["machine learning"]   
    },
    "Keras": {
        domains: ["ai"],
        focusSections: ["ai"],
        focusProcesses: ["machine learning"]   
    },
    "pyTorch": {
        domains: ["ai"],
        focusSections: ["ai"],
        focusProcesses: ["machine learning"]   
    },
    "AutoML": {
        domains: ["ai"],
        focusSections: ["ai"],
        focusProcesses: ["machine learning", "model selection"]   
    },
    // Web Development
    "Vue3/2": {
        domains: ["web-development"],
        focusSections: ["development", "deployment"],
        focusProcesses: ["front-end", "full-stack", "deployment"]   
    },
    "Kendo": {
        domains: ["web-development"],
        focusSections: ["development"],
        focusProcesses: ["front-end"]   
    },
    "Nuxt": {
        domains: ["web-development"],
        focusSections: ["development"],
        focusProcesses: ["front-end"]   
    },
    "Figma": {
        domains: ["web-development"],
        focusSections: ["design"],
        focusProcesses: ["prototyping", "wireframing", "ui/ux design"]   
    },
    "Apache": {
        domains: ["web-development"],
        focusSections: ["deployment"],
        focusProcesses: ["deployment"]   
    },
    "FastAPI": {
        domains: ["web-development"],
        focusSections: ["development"],
        focusProcesses: ["back-end"]   
    },
    "Django": {
        domains: ["web-development"],
        focusSections: ["development"],
        focusProcesses: ["back-end"]   
    },
    "Nginx": {
        domains: ["web-development"],
        focusSections: ["deployment"],
        focusProcesses: ["deployment"]   
    },

};

export default {
    "stack_items": stack_items
};