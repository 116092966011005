const projectSlides = {
  streaming_project: {
    0: {
      title: "Objective:",
      img: require("@/assets/images/projects/streaming_project/streaming_app_lineplot.webp"),
      thumb: require("@/assets/images/projects/streaming_project/streaming_app_lineplot_thumb.webp"),
      description: "Create a visualization tool that displayes trends in multi-source raw online streaming data."
    },
    1: {
      title: "Solution:",
      img: require("@/assets/images/projects/streaming_project/streaming_app_summary_table.webp"),
      thumb: require("@/assets/images/projects/streaming_project/streaming_app_summary_table_thumb.webp"),
      description: "The App allowed for seamless expolration of the data along multiple variables and output options, including interactive plots and tables"
    },
    2: {
      title: "Result:",
      img: require("@/assets/images/projects/streaming_project/streaming_app_barplot.webp"),
      thumb: require("@/assets/images/projects/streaming_project/streaming_app_barplot_thumb.webp"),
      description: "This Solution greatly increased the efficiency of monitoring trends in the data, as it replaced over 3 reports while being available on demand."
    }
  },
  projections_project: {
    0: {
      title: "Objective:",
      img: require("@/assets/images/projects/projections_project/startup_user_growth_projection.webp"),
      thumb: require("@/assets/images/projects/projections_project/startup_user_growth_projection_thumb.webp"),
      description: "Create a tool to mathematically model the marketing campaign of a startup"
    },
    1: {
      title: "Solution:",
      img: require("@/assets/images/projects/projections_project/startup_user_growth_projection.webp"),
      thumb: require("@/assets/images/projects/projections_project/startup_user_growth_projection_thumb.webp"),
      description: "An App consisting of three main parts: sidebar with the input parameters, an interactive plot, and an output table"
    },
    2: {
      title: "Result:",
      img: require("@/assets/images/projects/projections_project/startup_breakeven_analysis.webp"),
      thumb: require("@/assets/images/projects/projections_project/startup_breakeven_analysis.webp"),
      description: "This App allowes to interactively explore different scenarios along marketing expenses, user gains, revenue and cost dynamics, and conduct breakeven analyses"
    }
  },
  trading_project: {
      0: {
          title: "Objective:",
          img: require("@/assets/images/projects/trading_project/twitter.webp"),
          thumb: require("@/assets/images/projects/trading_project/twitter_thumb.webp"),
          description: "To create a Proof-Of-Concept solution to investigate the viability of using social media data as a predictor for cryptocurrency prices"
        },
      1: {
          title: "Solution:",
          img: require("@/assets/images/projects/trading_project/trading.webp"),
          thumb: require("@/assets/images/projects/trading_project/trading_thumb.webp"),
          description: "Consists of a pipeline for capture and processing of social media data focused on cryptocurrencies and an AI trading algorithm to suggest trading strategies"
      },
      2: {
          title: "Result:",
          img: require("@/assets/images/projects/trading_project/trader_win.webp"),
          thumb: require("@/assets/images/projects/trading_project/trader_win_thumb.webp"),
          description: "The trading algorithm showed significant profitability in backtesting, thereby proving that social media data could be used as a predictor for cryptocurrency prices"
      }
  },
  
  realscanner: {
      0: {
      title: "Objective:",
      img: require("@/assets/images/projects/realscanner/realscanner_welcome_page.webp"),
      thumb: require("@/assets/images/projects/realscanner/realscanner_welcome_page_thumb.webp"),
      description: "To build a search engine for Real Estate professionals to aid their investment decision making using AI and save time"
    },
    1: {
      title: "Solution:",
      img: require("@/assets/images/projects/realscanner/realscanner_search_interface.webp"),
      thumb: require("@/assets/images/projects/realscanner/realscanner_search_interface_thumb.webp"),
      description: "This platform assembles data on investment opportunities from multiple sources, estimates their market value using AI, and presents the results in an efficient manner"
    },
    2: {
      title: "Result:",
      img: require("@/assets/images/projects/realscanner/sunrise.webp"),
      thumb: require("@/assets/images/projects/realscanner/sunrise_thumb.webp"),
      description: "RealScanner is currently in early access testing"
    }
  },
}

export default {
  projectSlides
};