<template>
  <div class="scrolling-text">
    <span
      v-for="(skill, title) in shuffledSkills"
      :key="title"
      :class="{ 'skill': true, 'highlight': isHighlighted(skill) }"
      @mouseenter="updateFocusedSkill(title)"
      @mouseleave="updateFocusedSkill(null)"
    >{{ title }}</span>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { eventBus } from '@/event-bus.js';
import stackItems from '@/misc/stackItems.js'
import _ from 'lodash'

export default {
  props: {
    domain: String
  },
  setup(props) {
    const focusedSection = ref('')
    const focusedProcess = ref('')

    const filteredSkills = computed(() => {
      return Object.entries(stackItems["stack_items"]).reduce((acc, [title, skill]) => {
        if (skill.domains.includes("all") || skill.domains.includes(props.domain)) {
          acc[title] = skill;
        }
        return acc;
      }, {});
    })

    const shuffledSkills = computed(() => {
      return _.shuffle(Object.entries(filteredSkills.value)).reduce((acc, [title, skill]) => {
        acc[title] = skill;
        return acc;
      }, {});
    })

    const isHighlighted = computed(() => {
      return (skill) => {
        return (
          ((skill.focusSections.includes("all") && focusedSection.value !== undefined) || skill.focusSections.includes(focusedSection.value)) &&
          (skill.focusProcesses.includes("all") || focusedProcess.value === undefined || skill.focusProcesses.includes(focusedProcess.value))
        );
      };
    });

    eventBus.on('updateFocusedSectionProcess', (args) => {
      focusedSection.value = args["section"];
      focusedProcess.value = args["process"];
    })

    function updateFocusedSkill(skill) {
      eventBus.emit('updateFocusedSkill', skill);
    }

    return {
      shuffledSkills,
      isHighlighted,
      updateFocusedSkill
    }
  },
  beforeUnmount() {
    eventBus.off('updateFocus')
  }
}
</script>