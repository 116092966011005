<template>
    <header>
        <span class="logo">
          <a class="text-logo">Nate Alexeev</a>
        </span>

        <span class="shortcuts">
          <a class="shortcut-link" href="#projects">Projects</a>
        </span>
      </header>
</template>

<script>
export default {
  name: 'SimpleHeader'
}
</script>
