const quotes_steps = [
    {
      id: 0,
      quote: "Plans are nothing; planning is everything.",
      author: "-Dwight D. Eisenhower",
      focusedElementId: "understand",
    },
    {
      id: 1,
      quote: "The best way to predict the future is to invent it.",
      author: "-Abraham Lincoln",
      focusedElementId: "data-analytics-link",
    },
    {   
      id: 2,
      quote: "The only way to do great work is to love what you do.",
      author: "-Steve Jobs",
      focusedElementId: "data-analytics-link",
    },
  ];

  const quotes_projects = [
    {
      id: 0,
      quote: "New quote for AI",
      author: "-New author for AI",
      focusedElementId: "ai-link",
    },
    {
      id: 1,
      quote: "New quote for Data Analytics",
      author: "-New author for Data Analytics",
      focusedElementId: "data-analytics-link",
    },
    // Add more quotes as needed
  ];
  
  export default {
    "steps": quotes_steps,
    "projects": quotes_projects
  };