<template>
  <Carousel class="projectsCarousel" ref="projectsCarousel" :breakpoints="responsive" :direction="'vertical'" :wrap-around="true" @slide-start="updateCurrentProjectSlide" @mousedown="startClick" @mouseup="endClick">
    <Slide v-for="(project, index) in projects" :key="index">
      <div :class="`carousel__item project_item ${project.className}`" @click="handleClick">
        <div class="project-title-container">
          <h3>{{ project.title }}</h3>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <!--<Pagination />-->
    </template>
  </Carousel>

  <ProjectDetails :swipeDirection="swipeDirection" :activeProject="activeProject" :activeSlides="activeSlides"/>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ProjectDetails from '@/components/ProjectDetails.vue'
import projects from '@/misc/projects.js'
import projectSlides from '@/misc/projectSlides.js'
import { eventBus } from '@/event-bus.js';

export default defineComponent({
  props: {
      width: {
        type: Number
      }
    },
  name: 'WrapAround',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ProjectDetails
  },
  data() {
    return {
      projects: projects["projects"],
      projectSlides: projectSlides["projectSlides"],
      clickStartX: 0,
      clickStartY: 0,
      clickEndX: 0,
      clickEndY: 0,
      prevProjectIdx: 0,
      activeProjectIdx: 0,
      swipeDirection: undefined,
      activeProject: undefined,
      activeSlides: undefined,
      }
  },
  computed: {
    responsive() {
      return {
        0: { itemsToShow: 1 },
        600: { itemsToShow: 1.1 },
        700: { itemsToShow: 1.2 },
        800: { itemsToShow: 1.3 },
        900: { itemsToShow: 1.4 },
        1100: { itemsToShow: 1.5 },
        1200: { itemsToShow: 1.6 },
        1300: { itemsToShow: 1.7 },
        1400: { itemsToShow: 1.8 },
        1500: { itemsToShow: 1.9 },
        1600: { itemsToShow: 2 }
      }
    },
    itemsToShow() {
      const width = window.innerWidth;
      if (width >= 1600) return 2;
      if (width >= 1500) return 1.9;
      if (width >= 1400) return 1.8;
      if (width >= 1300) return 1.7;
      if (width >= 1200) return 1.6;
      if (width >= 1100) return 1.5;
      if (width >= 900) return 1.4;
      if (width >= 800) return 1.3;
      if (width >= 700) return 1.2;
      if (width >= 600) return 1.1;
      return 1;
    }
  },
  mounted() {
    this.activeProjectIdx = 0;
    this.activeProject = this.projects[this.activeProjectIdx];
    this.activeSlides = this.projectSlides[this.activeProject["className"]];

    eventBus.on('nextProject', this.nextProject);
    eventBus.on('prevProject', this.prevProject);
  },
  methods: {
    startClick(event) {
      this.clickStartX = event.clientX;
      this.clickStartY = event.clientY;
    },
    endClick(event) {
      this.clickEndX = event.clientX;
      this.clickEndY = event.clientY;
    },
    isClick() {
      const threshold = 5; // Adjust this value as needed
      const dx = Math.abs(this.clickEndX - this.clickStartX);
      const dy = Math.abs(this.clickEndY - this.clickStartY);
      return dx < threshold && dy < threshold;
    },
    updateCurrentProjectSlide(slide) {
      let newIdx = slide.slidingToIndex;

      const nProjects = this.projects.length;
      if (newIdx == nProjects) {
        newIdx = 0;
      } else if (newIdx == -1) {
        newIdx = nProjects - 1;
      }

      const maxIdx = nProjects - 1;
      // manage the wrap around edge cases 
      if (((newIdx * this.prevProjectIdx) == 0) && (newIdx + this.prevProjectIdx) == maxIdx)  {
        if (newIdx < this.prevProjectIdx) {
          this.swipeDirection = "right";
        } else {
          this.swipeDirection = "left";
        }
      } else {
          // simple logic from here
          if (newIdx > this.prevProjectIdx) {
            this.swipeDirection = "right";
          } else {
            this.swipeDirection = "left"
          }
      }
      
      this.prevProjectIdx = newIdx;

      this.activeProjectIdx = newIdx;
      //console.log('New activeProjectIdx:', this.activeProjectIdx);

      this.activeProject = this.projects[this.activeProjectIdx];
      //console.log('New activeProject:', this.activeProject);

      this.activeSlides = this.projectSlides[this.activeProject["className"]];
      //console.log('New activeSlides:', this.activeSlides);
    },
    nextProject() {
      this.swipeDirection = "right";
      this.$refs.projectsCarousel.next();
    },
    prevProject() {
      this.swipeDirection = "left";
      this.$refs.projectsCarousel.prev();
    }
  }
})
</script>
