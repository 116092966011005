<template>
  <SimpleHeader/>

  <div class="frame1">
    <div class="content">
      
      <TypewriterTitle/>

      <SimpleFooter/>

    </div>
  </div>

  <div class="frame2" id="how-i-do-it">

    <ProcessSteps/>

    <Quote partner="steps"/>
  </div>

  <DomainSections/>

  <div class="projects-frame" id="projects">
    <ProjectsCarousel :width="width"/>
  </div>
  
  <ContactForm/>
  
</template>

<script>
import SimpleHeader from './components/SimpleHeader.vue'
import TypewriterTitle from './components/TypewriterTitle.vue'
import ProcessSteps from './components/ProcessSteps.vue'
import DomainSections from './components/DomainSections.vue'
import ContactForm from './components/ContactForm.vue'
import ProjectsCarousel from './components/ProjectsCarousel.vue'
import Quote from './components/Quote.vue'
import SimpleFooter from './components/SimpleFooter.vue'

export default {
  components: {
    SimpleHeader,
    TypewriterTitle,
    ProcessSteps,
    Quote,
    DomainSections,
    ContactForm,
    ProjectsCarousel,
    SimpleFooter
}
}
</script>
