<template>
  <div class="typewriter-wrapper">
      <h1 class="typewriter-title" ref="titleElement">
      {{ displayedTitle }}
      <span class="cursor" :class="{ 'cursor-hidden': !isTyping }">&nbsp;</span>
      </h1>
      <button class="btn btn-primary" id="now-button" v-if="!isTyping" @click="gotoContactForm">Now!</button>
  </div>
</template>

<script>
export default {
data () {
  return {
    title: 'Digital Transformation Solutions',
    titleWords: [],
    displayedTitle: '',
    isTyping: true,
    typingSpeed: 1000 // Adjust typing speed in milliseconds
  }
},
mounted () {
  this.titleWords = this.title.split(' ')
  setTimeout(() => {this.type();}, 1000);
},
methods: {
  type () {
    if (this.titleWords.length > 0) {
      this.displayedTitle += this.titleWords.shift() + ' '
      setTimeout(() => this.type(), this.typingSpeed)
    } else {
      this.isTyping = false
    }
  },

  gotoContactForm() {
    const contactForm = document.getElementById('name')
    contactForm.scrollIntoView({ behavior:'smooth' })
    contactForm.focus()
  }
}
}
</script>
