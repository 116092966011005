import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery';

// Import CSS files
import './css/global.css';
import './css/header.css';
import './css/TypewriterTitle.css';
import './css/frame1.css';
import './css/ProcessSteps.css';
import './css/quote.css';
import './css/frame2.css';
import './css/DomainSections.css';
import './css/StackLine.css';
import './css/ContactForm.css';
import './css/ContactInfo.css';
import './css/ProjectsCarousel.css';
import './css/ProjectDetails.css';
import 'vue3-carousel/dist/carousel.css';


const app = createApp(App);

app.use(router).mount('#app');
