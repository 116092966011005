<template>
    <div ref="expandImage">
      <img
        :class="class"
        :src="thumb"
        @mousedown="startClick"
        @mouseup="endClick"
        @click="handleClick"
      />
      <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>
  </template>
  
  <script>
  import VueEasyLightbox from 'vue-easy-lightbox';
  import watch from "vue"

  import { eventBus } from '@/event-bus.js';

  export default {
    components: {
      VueEasyLightbox
    },
    props: {
      image: {
        type: String,
        required: true
      },
      thumb: {
        type: String,
        required: true
      },
      class: String
    },
    data() {
      return {
        visible: false,
        index: 0,
        imgs: [],
        clickStartX: 0,
        clickStartY: 0,
        clickEndX: 0,
        clickEndY: 0
      };
    },
    mounted() {
      document.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleDocumentClick);
    },
    methods: {
      startClick(event) {
        this.clickStartX = event.clientX;
        this.clickStartY = event.clientY;
      },
      endClick(event) {
        this.clickEndX = event.clientX;
        this.clickEndY = event.clientY;
      },
      handleClick() {
        if (this.isClick()) {
          this.showImage();
        }
      },
      stopMousedownPropagation(event) {
        console.log("click")
        event.stopPropagation();
      },
      isClick() {
        const threshold = 5; // Adjust this value as needed
        const dx = Math.abs(this.clickEndX - this.clickStartX);
        const dy = Math.abs(this.clickEndY - this.clickStartY);
        return dx < threshold && dy < threshold;
      },
      showImage() {
        this.imgs = [this.image];
        this.visible = true;
      },
      handleHide() {
        this.visible = false;
      },
      handleDocumentClick(event) {
        if (this.$refs.expandImage && !this.$refs.expandImage.contains(event.target)) {
          this.handleHide();
        }
      },
      handleScroll(event) {
        console.log("scroll")
        event.preventDefault();
      },
      updateScrollListener() {
        if (this.visible) {
          this.$refs.expandImage.addEventListener('wheel', this.handleScroll, { passive: false });
        } else {
          this.$refs.expandImage.removeEventListener('wheel', this.handleScroll);
        }
      }
    },
    watch: {
      visible(newValue) {
        // tell projectDetails to lock the carousel
        eventBus.emit('toggleDrag');

        // block default scrolling behavior, so the document doesnt run away on zoom
        this.updateScrollListener()
    }
      }
  };
  </script>
  