<template>
    <div class="process-steps">
        <span class="steps-container" ref="steps-container">
          <div class="step active" ref="step_0" @mouseenter="updateFocusedStep(0)">
            <span class="step-icon-row">
              <img class="step-icon" ref="step_arrow_0" src="@/assets/icons/icon_investigation.svg">
            </span>
            <div class="step-content">
              <h3 class="step-title">Understand</h3>
              <ul class="bulletpoints">
                <li class="bulletpoint">Investigate your processes</li>
                <li class="bulletpoint-bold">Leave no stone unturned</li>
                <li class="bulletpoint">Formulate the objective</li>
              </ul>
            </div>
          </div>
          <img class="icon-arrow" ref="step_arrow_1" src="@/assets/icons/icon_arrow.svg">
          <div class="step" ref="step_1" @mouseenter="updateFocusedStep(1)">
            <span class="step-icon-row">
              <img class="step-icon" src="@/assets/icons/icon_idea.svg">
            </span>
            <div class="step-content">
              <h3 class="step-title">Engineer</h3>
              <ul class="bulletpoints">
                <li class="bulletpoint">Explore diverse approaches</li>
                <li class="bulletpoint-bold">Define the technical task</li>
                <li class="bulletpoint">Ensure mutual understanding</li>
              </ul>
            </div>
          </div>
            <img class="icon-arrow" ref="step_arrow_2" src="@/assets/icons/icon_arrow.svg">
          <div class="step" ref="step_2" @mouseenter="updateFocusedStep(2)">
            <span class="step-icon-row">
              <img class="step-icon" src="@/assets/icons/icon_implementation.svg">
            </span>
            <div class="step-content">
              <h3 class="step-title">Implement</h3>
              <ul class="bulletpoints">
                <li class="bulletpoint">Bring the Solution to life</li>
                <li class="bulletpoint-bold">Ensure customer success</li>
                <li class="bulletpoint">Do my best work!</li>
              </ul>
            </div>
          </div>
        </span>
      </div>
</template>

<script>
import { eventBus } from '@/event-bus.js';
export default {
  methods: {
    updateFocusedStep(elementId) {
      // Emit event to update the Quote.
      eventBus.emit('updateFocusedStep', elementId);

      // change the styles a bit to hightlight the active step

      // reset the active step
      let steps = this.$refs["steps-container"].getElementsByClassName('step')
      for (let i = 0; i < steps.length; i++) {
        steps[i].classList.remove('active')
      }

      // reset the active arrow
      let arrows = this.$refs["steps-container"].getElementsByClassName('icon-arrow')
      for (let i = 0; i < arrows.length; i++) {
        arrows[i].classList.remove('active')
      }

      // add the active step
      let step = this.$refs["step_" + elementId]
      step.classList.add('active')

      // add the active arrows
      if (elementId > 0) {
        let arrow1 = this.$refs["step_arrow_1"]
        arrow1.classList.add('active')
      } if (elementId > 1) {
        let arrow2 = this.$refs["step_arrow_2"]
        arrow2.classList.add('active')
      }
    }
  } 
};
</script>
